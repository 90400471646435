import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { menu } from "./menu";

const contact = {
  adress: "경기도 안양시 동안구 시민대로327번길 12-30 5층",
  tell: " Tel 02. 851. 2662 / Fax 02. 851. 2655",
  email: " support@maxius.io",
};

const clickOutsideRef = (content_ref: any, toggle_ref: any) => {
  const btnAmazing = document.querySelector(
    ".changeLangWrapperMenu.amazing"
  ) as any;
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      toggle_ref.current.classList.toggle("open");
      content_ref.current.classList.toggle("open");
      btnAmazing?.classList.toggle("open");
    } else if (btnAmazing?.contains(e.target)) {
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        toggle_ref.current.classList.remove("open");
        content_ref.current.classList.remove("open");
        btnAmazing?.classList.remove("open");
      }
    }
  });
};

const NavBar = () => {
  //setup dropdown menu items
  const [isOpen, setIsOpen] = useState("");
  // end
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const handleToggleMenu = () => {
    document.getElementById("menu")?.classList.remove("open");
    document.getElementById("toggle")?.classList.remove("open");
    document
      .querySelector(".changeLangWrapperMenu.amazing")
      ?.classList.remove("open");
  };
  const navigate = useNavigate();

  const toggle_el = useRef(null);
  const content_el = useRef(null);

  useEffect(() => {
    if (content_el.current && toggle_el.current) {
      clickOutsideRef(content_el, toggle_el);
    }
  }, [toggle_el, content_el]);

  const handleLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const renderTextNavbar = () => {
    const data = t("navbar", { returnObjects: true }) as any;
    return <>{data.address}</>;
  };

  return (
    <div className="menuAreaWrapper">
      <nav role="navigation">
        <div id="menuToggle">
          <div className="changeLangWrapper real">
            <div
              onClick={() => handleLanguage("en")}
              className={`changeLang en ${i18n.language === "en" ? "select" : ""
                }`}
            >
              EN
            </div>
            <div
              onClick={() => handleLanguage("ko")}
              className={`changeLang ${i18n.language === "ko" ? "select" : ""}`}
            >
              KR
            </div>
          </div>
          <div ref={toggle_el} id="toggle" className="btn-toggle">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="changeLangWrapperMenu amazing">
            <div
              onClick={() => handleLanguage("en")}
              className={`changeLangMemnu en ${i18n.language === "en" ? "select" : ""
                }`}
            >
              EN
            </div>
            <div
              onClick={() => handleLanguage("ko")}
              className={`changeLangMemnu ko ${i18n.language === "ko" ? "select" : ""
                }`}
            >
              KR
            </div>
          </div>
          <ul ref={content_el} id="menu">
            <div className="list-menu-sidebar">
              {menu.map((val, key) => {
                return (
                  <div
                    key={val.index}
                    onClick={() => {
                      if (isOpen === val.name) {
                        setIsOpen("");
                      } else {
                        setIsOpen(val.name);
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    className={`list ${isOpen === val.name && !val.path ? "select" : ""
                      } btn_${key} ${pathname.toLocaleLowerCase() === val.path && "active"
                      }`}
                  >
                    <li
                      className={`btn-items-${key}`}
                      onClick={() => {
                        if (val.name === "Contact") {
                          handleToggleMenu();
                          navigate("/contact");
                        } else if (val.name === "Home") {
                          handleToggleMenu();
                          navigate("/");
                        }
                      }}
                    >
                      {val.name}
                    </li>
                    <div
                      className={`${isOpen === val.name
                        ? `list-items list-hidden${key}`
                        : "list-items"
                        }`}
                    >
                      {val.menuList.map((sub, key) => {
                        return (
                          <div key={key} className="list-items-menu">
                            <div
                              data-item="name"
                              className="text"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const tagA = document.createElement("a");
                                tagA.target = "_blank";
                                tagA.href = sub.src;
                                tagA.click();
                              }}
                            >
                              <a
                                href={sub.src}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                {sub.menu}{" "}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="infoWrapper">
              <p id="adress" className={i18n.language === "ko" ? "ko" : "en"}>
                {renderTextNavbar()}
                <br />
                {contact.tell}
              </p>
              <p className="link">
                <a href="/" target="blank">
                  View Map
                </a>
              </p>
              <br />
              <p>Company.</p>
              <p className="link">
                <a href={`mailto:${contact.email}`} title="">
                  {contact.email}
                </a>
              </p>
              <br />
              <p>Technical support </p>
              <p className="link">
                <a href={`mailto:${contact.email}`} title="">
                  {contact.email}
                </a>
              </p>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
