import "./pageTwo.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

function PageTwo() {
  const contianer = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const { i18n } = useTranslation();
  useEffect(() => {
    if (contianer && contianer.current) {
      console.log(contianer.current.clientWidth)
      setWidth(contianer.current?.clientWidth);
    }
  }, [contianer.current?.clientWidth])

  return (
    <div className="page-two" ref={contianer}>
      <div className="page-two-content">
        <div className="page-two-container">
          {i18n.language === "ko" ? (
            width <= 600 ?
              (
                <div className={`mo1 ko text_wr`}>
                  <p className="mo text-one">
                    <span className="number">1. </span><span className="note">자체 개발한 </span> 시스템
                  </p>
                  <p className="mo text-two ">
                    <span className="number">반도체 2. </span>고성능 서버
                  </p>
                  <p className="mo text-three ">
                    <span className="note">제조 판매</span>
                    <span className="number"> 3. </span>HPS기반{" "}
                  </p>
                  <p className="mo text-four ">
                    IPFS <span className="note">IDC 구축사업</span><span className="number"> 4. </span>
                  </p>
                  <p className="mo text-five">
                    블록체인 <span className="note">IPFS</span> 개발 및
                  </p>
                  <p className="mo text-six ">
                    운영 컨설팅
                  </p>
                </div>
              ) : (
                <div className={`text_wr ko`}>
                  <p className="text-one">
                    <span className="number">1. </span><span className="note">자체 개발한 </span> 시스템반도체
                  </p>
                  <p className="text-two other">
                    <span className="number">2. </span>고성능 서버
                    <span className="note"> 제조 판매</span>
                  </p>
                  <p className="text-three other">
                    <span className="number">3. </span>HPS기반 IPFS <span className="note">IDC 구축사업</span>

                  </p>
                  <p className="text-four other">
                    <span className="number">4. </span>블록체인 <span className="note">IPFS</span> 개발 및 운영 컨설팅
                  </p>
                </div>
              )
          ) : (
            width <= 600 ?
              (
                <div className={`mo1 en text_wr`}>
                  <p>
                    <p className="text-one">
                      1. <span className="note">Self-produced</span> semicond{"\n"}
                    </p>
                    <p className="text-two">
                      uctors 2. Production{" "}and Sa{"\n"}
                    </p>
                    <p className="text-three">
                      les of <span className="note"> H</span>igh-<span className="note">P</span>erformance <span className="note">S</span>{"\n"}
                    </p>
                    <p className="text-four">
                      ervers with self-manufactur{"\n"}
                    </p>
                    <p className="text-five">
                      ed semiconductors 3. Cons{"\n"}
                    </p>
                    <p className="text-six">
                      truction of operative<span className="note">{" "}Block</span>{"\n"}
                    </p>
                    <p className="text-seven">
                      <span className="note">chain IDC</span> based on high-pe{"\n"}
                    </p>
                    <p className="text-eight">
                      rformance servers 4. Establ{"\n"}
                    </p>
                    <p className="text-nine">
                      ish solution relating to Bloc{"\n"}
                    </p>
                    <p className="text-ten">
                      kchain<span className="note"> (IPFS) </span>{"\n"}
                    </p>
                    <p className="text-tenone">

                    </p>
                  </p>
                </div>
              ) :
              (
                <div className="text_wr en">
                  <p>
                    <p className="text-one">
                      1. <span className="note">Self-produced
                      </span> semiconductors 2. Production{" "}
                    </p>
                    <p className="text-two">
                      and Sales of <span className="note"> H</span>igh-<span className="note">P</span>erformance <span className="note"> S</span>ervers with self-
                    </p>
                    <p className="text-three">
                      manufactured semiconductors 3. Construction{" "}
                    </p>
                    <p className="text-four">
                      of operative <span className="note"> Block</span><span className="note">chain IDC </span>based on high-
                    </p>
                    <p className="text-five">
                      performance servers 4. Establish solution relating{" "}
                    </p>
                    <p className="text-six">
                      <span className="tion">to Blockchain</span><span className="note"> (IPFS) </span>
                    </p>
                  </p>
                </div>
              )
          )}
        </div>
      </div>
    </div >
  );
}

export default PageTwo;
