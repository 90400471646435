import gsap from "gsap/all";
import { Route, Routes, useLocation } from "react-router-dom";
import Contact from "../pages/Contact";
import Home from "../pages/Home";

const Router = () => {
  const { pathname } = useLocation();
  if (pathname === "/contact") {
    gsap.to(" .btn-toggle span ", { background: "#222" });
    gsap.to(".changeLang", { color: "#222222" });
    gsap.to(".maxius-logo a", { color: "#222222" });
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default Router;
