const test = require("../../assets/images/HGS 4024.pdf");
const HJS2224 = require("../../assets/images/HJS2224.pdf");
const TJS2125G = require("../../assets/images/TJS 2125G.pdf");
const HGS4024 = require("../../assets/images/HGS 4024.pdf");
const TJS104S = require("../../assets/images/TJS 104S.pdf");
const TJS212S = require("../../assets/images/TJS 212S+.pdf");
const HSS2224 = require("../../assets/images/HSS2224.pdf");
const IPFSDataCenterDevelopment = require("../../assets/images/IPFS Data Center Development&Operation Consulting.pdf");
const IPFSDataCenterBuildVision = require("../../assets/images/IPFS Data Center Build Vison.pdf");
const TechnologyApplication = require("../../assets/images/Technology Application.pdf");
export const menu = [
  {
    index: "q1",
    name: "Home",
    path: "/",
    menuList: [],
  },
  {
    index: "q2",
    name: "Brochure",
    menuList: [
      { menu: "HJS 2224", src: HJS2224 },
      { menu: "TJS 2125G", src: TJS2125G },
      { menu: "HGS 4024", src: HGS4024 },
      { menu: "TJS 104S", src: TJS104S },
      { menu: "TJS 212S+", src: TJS212S },
      { menu: "HSS 2224", src: HSS2224 },
    ],
  },
  {
    index: "q3",
    name: "Proposal",
    menuList: [
      {
        menu: "IPFS Data Center Development & Operation Consulting",
        src: IPFSDataCenterDevelopment,
      },
      { menu: "IPFS Data Center Build Vision", src: IPFSDataCenterBuildVision },
      { menu: "Technology Application", src: TechnologyApplication },
    ],
  },
  {
    index: "q4",
    name: "Contact",
    path: "/contact",
    menuList: [],
  },
];
