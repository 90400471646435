import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Contact = () => {
  useEffect(() => {
    const titleContact = document.querySelector(".contact-title");
    const mapContact = document.querySelector(".box-map");
    const titleFirst = document.querySelector(".contact-title-1");
    const titleSecond = document.querySelector(".contact-title-2");
    const revealAnim = () => {
      const TLFade = gsap.timeline();
      TLFade.from(
        titleContact,
        {
          autoAlpha: 0,
          x: -380,
          duration: 1,
          ease: "power4.out",
        },
        "-=0.2"
      )
        .from(
          mapContact,
          { autoAlpha: 0, x: -40, duration: 1, ease: "power4.out" },
          "-=0.2"
        )
        .from(titleFirst, {
          autoAlpha: 0,
          x: -120,
          duration: 0.5,
          ease: "power4.out",
        })
        .from(titleSecond, {
          autoAlpha: 0,
          x: -120,
          duration: 0.5,
          ease: "power4.out",
        });
    };

    revealAnim();
  }, []);
  const { t, i18n } = useTranslation();

  const renderTextContact = (type: string) => {
    const data = t("contact", { returnObjects: true }) as any;
    return data[type];
  };
  return (
    <div className="contact-container">
      <div className="contact-content">
        <header>
          <h1 className="contact-title">Contact us</h1>
        </header>
        <div className="box-map">
          <div className="map-child">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3169.5988687754716!2d126.9678097!3d37.3993176!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5f4218184263%3A0x5e34b10899e72086!2z66el7Iuc7Jqw7Iqk!5e0!3m2!1sko!2s!4v1645603511540!5m2!1sko!2s"
              style={{
                border: 0,
              }}
              loading="lazy"
              title="map"
            ></iframe>
          </div>
          <div className="map-child info-wrapper">
            <div className="info">
              <div className={"top " + (i18n.language === "ko" ? "ko" : "en")}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: renderTextContact("address") as any,
                  }}
                />
                <p> Tel 02. 851. 2662</p> <p> Fax 02. 851. 2655</p>
                <div className="content-mobile">
                  <div className="contact-title-1">
                    <div className="title ">Company</div>
                    <a href="mailto:support@maxius.io" title="">
                      support@maxius.io
                    </a>
                  </div>

                  <div className="hidden"></div>
                  <div className="contact-title-2">
                    <div className="title ">Technical support </div>
                    <a href="mailto:support@maxius.io" title="">
                      support@maxius.io
                    </a>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="contact-title-1">
                  <div className="title ">Company</div>
                  <a href="mailto:support@maxius.io" title="">
                    support@maxius.io
                  </a>
                </div>

                <div className="hidden"></div>
                <div className="contact-title-2">
                  <div className="title ">Technical support </div>
                  <a href="mailto:support@maxius.io" title="">
                    support@maxius.io
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
