import "./pageThree.scss";
import { useTranslation } from "react-i18next";
function PageThree() {
  const { i18n } = useTranslation();

  return (
    <div className="page-three">
      <div className="page-three-container">
        <div className="page-three-content">
          {i18n.language === "ko" ? (
            <>
              <div className="item ko">
                <div className="item-content">
                  <p className="title title_first">Product</p>
                  <p className="desc ko">
                    다양한 영역 에서 응용 가능한{" "}
                    <span className="note">High-Performance Server</span>
                    <br />
                    각종 I/O 장치에 적용 가능한 <span className="note">Storage</span><br /> 최신 기술이 접목된 <span className="note">IDC 구축</span>

                    <br />
                    IPFS등 다양한{" "}
                    <span className="note">Blockchain Solution </span>
                    <br />
                    <br />
                    Maxius 는 다양한 제품 과 Solution 을 <br />공급 하여 세계
                    시장 속 에서 지속 성장 하는
                    <br /> 기업 으로 발전해 나가겠습니다 .
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title title_second">Technology</p>
                  <p className="desc ko">
                    20년 이상의 연구 노하우와 기술력을 기반으로 <br />
                    국내 유일의{" "}
                    <span className="note">자체개발 시스템반도체</span>
                    를 이용하여
                    <br />
                    HPC 서버를 만드는 회사로{" "}
                    <span className="note">지능형 데이터 센터</span>와<br />
                    IT산업분야에 <span className="note">특화된 솔루션</span>을
                    제공하고 있습니다.
                    <br />
                    <br />
                    Maxius는 20년 이상 축적된 높은 기술력으로
                    <br />
                    서비스로 차별된 제품과 Solution을 공급하기
                    <br />
                    위하여 노력하고 있습니다.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title title_third">Application</p>
                  <p className="desc ko">
                    Maxius의 각종 제품은{" "}
                    <span className="note">
                      인공지능/유전체분석,
                      <br />
                      엣지컴퓨팅, IDC센터
                    </span>{" "}
                    등에 활용되고 있으며
                    <br />
                    다양한 Solution은 방대한{" "}
                    <span className="note">데이터분석</span> 및 <br />
                    <span className="note">분산처리기능, IPFS IDC</span> 등에
                    활용되고 있습니다.
                    <br />
                    <br />
                    Maxius는 연구개발과 Communication을 통하여 <br />
                    다양한 분야에 유용한 제품 및 Solution을 <br />
                    공급하도록 하겠습니다.
                    <br />
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title title_fourth note">Blockchain</p>
                  <p className="desc ko">
                    메타버스에 활용되는{" "}
                    <span className="note">스토리지 및 서비스</span>
                    <br />
                    Web3.0의{" "}
                    <span className="note">IPFS 스토리지 및 응용서비스</span>

                    <br />
                    <br />
                    Maxius는 탈중앙화된 스토리지에 최적화된 <br />
                    H/W와 S/W를 통합 제공하여 고객의 니즈를 <br />
                    맞추고 최고의 고부가가치를 창출하겠습니다.{" "}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="item en">
                <div className="item-content">
                  <p className="title title_first">Product</p>
                  <p className="desc en">
                    <span className="note">High-Performance Server</span> with an extensive selection of serviceable capabilities

                    <br />
                    Building <span className="note">IDC</span>; incorporating the latest <span className="note">Storage</span> technology applicable to various I/O devices

                    <br />
                    Various  <span className="note">Blockchain Solutions</span> , including IPFS.

                    <br />
                    <br />
                    Maxius will continue to develop as a company in the global market by supplying a variety of products and solutions.

                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title title_second">Technology</p>
                  <p className="desc en">
                    Based on more than 20 years of research know-how <br />
                    and technology, we are the only company in Korea <br />
                    that makes HPC servers{" "}
                    <span className="note">using self-developed system</span>
                    <br />
                    semiconductors and{" "}
                    <span className="note">provides solutions specialized</span>
                    <br />
                    in <span className="note">
                      the intelligent data center
                    </span>{" "}
                    in the IT industry.
                    <br />
                    <br />
                    With our services, Maxius strives to supply<br /> differentiated products and solutions.

                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title title_third">Application</p>
                  <p className="desc en">
                    Maxius' miscellaneous products are used for {" "}
                    <span className="note">AI/genetic analysis, </span>
                    <br />
                    <span className="note">
                      edge computing, IDC centers,
                    </span>{" "}
                    as well as various solutions
                    <br />
                    used for vast {" "}
                    <span className="note">data analysis, </span> {" "}
                    <span className="note">distributed</span>
                    <br />
                    <span className="note">processing functions and IPFS IDC </span>
                    , etc.
                    <br />
                    <br />
                    Maxius will supply serviceable products and solutions to<br /> varying fields through R&D and communication.

                  </p>
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <p className="title title_fourth note">Blockchain</p>
                  <p className="desc en">
                    <span className="note">storage and service</span>{" "}
                    utilized in Metaverse <br />
                    <span className="note">
                      IPFS Storage and Application Services
                    </span>{" "}
                    in Web3.0 <br />

                    <br />
                    Maxius provides both H/W and S/W, which are optimized for{" "}
                    decentralized storage to match customer needs and create the{" "}
                    best added value.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div >
  );
}

export default PageThree;
