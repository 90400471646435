import PageOne from "./PageOne/PageOne";
import PageTwo from "./PageTwo/PageTwo";
import PageThree from "./PageThree/PageThree";
import PageFourth from "./PageFourth/PageFourth";
import PageFive from "./PageFive/PageFive";
import ReactFullpage from "@fullpage/react-fullpage";
import onLeave from '../../components/Animation/onLeave';
import afterLoad from '../../components/Animation/AfterLoad';
import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const fullpageOptions = {
  anchors: ["firstPage", "secondPage", "thirdPage", "fourthPage", "fivePage"],

  afterSlideLoad: (section: any) => {
    var loadedSlide = this;

    console.log(section);
  }
};

function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);

  let vh = 0;

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    console.log(vh);
  }, []);

  return (
    <ReactFullpage
      licenseKey={'263DXXXX-B839XXXX-AE67XXXX-F398XXXX'}
      {...fullpageOptions}
      navigation={true}
      navigationPosition={'right'}
      onLeave={onLeave}
      afterLoad={afterLoad}
      scrollingSpeed={400}
      normalScrollElements={'.item-history, #menu,list-menu-sidebar'}
      afterSlideLoad={(section: any) => {
        console.log(section);
      }}

      render={() => {

        return (
          <ReactFullpage.Wrapper>
            <div>
              <section className="section">
                <PageOne />
              </section>
              <section className="section">
                <PageTwo />
              </section>
              <section className="section">
                <PageThree />
              </section>
              <section className="section">
                <PageFourth />
              </section>
              <section className="section">
                <PageFive />
              </section>

            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default memo(Home);
